.App {
  text-align: center;
  /* width: 90%; */
  margin: 0 auto;
  /* background-color:#000000; */
  color: #000000;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/* Homepage */
.expert-h3 {
  font-size: 25px;
  margin-top: 50px;
  font-weight: 900;
  margin-bottom: 25px;
  letter-spacing: 8px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 41px;
  background: rgba(0, 0, 0, 0.8);
  display: inline-block;
  padding: 10px 25px;
}

.client-h3 {
  font-size: 25px;
  margin-top: 50px;
  font-weight: 900;
  margin-bottom: 25px;
  letter-spacing: 8px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 41px;
  background: rgba(0, 0, 0, 0.8);
  display: inline-block;
  padding: 10px 25px;
}

.work-h3 {
  font-size: 25px;
  margin-top: 50px;
  font-weight: 900;
  margin-bottom: 25px;
  letter-spacing: 8px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 41px;
  background: rgba(0, 0, 0, 0.8);
  display: inline-block;
  padding: 10px 25px;
}

/* NavBar */

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  /* background-color: #1e1e1e; */
}

@media (max-width: 800px) {
  .navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
  .navbar-pic {
    width: 200px;
  }

  .link {
    text-decoration: none;
    letter-spacing: 0.1em;
    font-weight: 700;
    color: #000000;
    text-align: left;
    margin: 5.9px;
  }
}

@media (max-width: 500px) {
  .navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px;
    align-items: center;
    font-size: 14px;
    width: 100%;
  }
}

.navbar-links {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  justify-content: space-between;
  letter-spacing: 1px;
}
@media (max-width: 500px) {
  .navbar .link-line {
    border-bottom: 1px solid lightgrey;
    width: 100%;
  }

  .navbar-links {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    justify-content: space-between;
    letter-spacing: 1px;
    width: 100%;
  }
}

.navbar-pic {
  max-width: 255px;
  display: flex;
  justify-content: flex-start;
  margin: 10px;
}

.link {
  text-decoration: none;
  letter-spacing: 0.1em;
  font-weight: 700;
  color: #000000;
  margin: 10px;
}

@media (max-width: 500px) {
  .link {
    text-decoration: none;
    letter-spacing: 0.1em;
    font-weight: 700;
    color: #000000;
    margin-top: 0px;
    border-bottom: 1px solid lightgrey;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .link:after {
    content: ">>";
  }
}
.link:hover {
  color: #a1a1a1;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow: hidden;
}

/* Contact Us Form */

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 31px 120px;
  align-items: flex-start;
}

@media (max-width: 500px) {
  .form-container {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    padding: 0;
    align-items: center;
    width: 90%;
  }
}

@media (max-width: 800px) {
  .form-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
    align-items: center;
    width: 90%;
    margin: 0 auto;
  }
}

.form button {
  background: transparent;
  text-transform: uppercase;
  color: #222222;
  padding: 13px 26px;
  border: 2px solid #222222;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 1.2px;
  margin-top: 20px;
  width: 13%;
  margin-bottom: 25px;
}

@media (max-width: 500px) {
  .form button {
    background: transparent;
    text-transform: uppercase;
    color: #222222;
    padding: 13px 26px;
    border: 2px solid #222222;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: 1.2px;
    margin-top: 20px;
    width: 100%;
  }
}

@media (max-width: 800px) {
  .form button {
    background: transparent;
    text-transform: uppercase;
    color: #222222;
    padding: 13px 26px;
    border: 2px solid #222222;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: 1.2px;
    margin-top: 20px;
    width: 100%;
  }
}

.form button:hover {
  background: #222222;
  color: #ffffff;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.contact-h3 {
  font-size: 25px;
  margin-top: 50px;
  font-weight: 900;
  margin-bottom: 25px;
  letter-spacing: 8px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 41px;
  background: rgba(0, 0, 0, 0.8);
  display: inline-block;
  padding: 10px 25px;
}

@media (max-width: 800px) {
  .contact-h3 {
    font-size: 25px;
    margin-top: 50px;
    font-weight: 900;
    margin-bottom: 25px;
    letter-spacing: 8px;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 41px;
    background: rgba(0, 0, 0, 0.8);
    display: inline-block;
    padding: 10px 25px;
    margin-bottom: 10%;
  }
}

.contact {
  width: 25%;
  text-align: left;
}

.contact-person h3 {
  font-size: 2rem;
  font-weight: 900;
  margin-top: 50px;
  outline: none;
  letter-spacing: 1px;
}

.contact-person p {
  margin-top: 17px;
  letter-spacing: 0.17px;
  font-size: 17px;
  font-weight: 400;
}

@media (max-width: 500px) {
  .contact {
    display: flex;
    flex-wrap: wrap;
    width: 100% !important;
    align-items: center !important;
    text-align: center !important;
  }

  .contact-person h3 {
    font-size: 1.4rem !important;
    font-weight: 900;
    margin-top: 50px;
    outline: none;
    letter-spacing: 1px;
    margin: 0 auto;
  }

  .contact-person p {
    margin-top: 17px;
    letter-spacing: 0.17px;
    font-size: 15px !important;
    font-weight: 400;
  }
}

@media (max-width: 800px) {
  .contact {
    display: flex;
    flex-direction: column;
    width: 65%;
    text-align: left;
    /* text-align: center; */
    margin: 0 auto;
  }

  .contact-person h3 {
    font-size: 2rem;
    font-weight: 900;
    margin-top: 50px;
    outline: none;
    letter-spacing: 1px;
    margin: 0 auto;
  }

  .contact-person p {
    margin-top: 17px;
    letter-spacing: 0.17px;
    font-size: 17px;
    font-weight: 400;
  }

  .contact-person {
    margin-bottom: 25px;
  }
}

.form {
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

@media (max-width: 500px) {
  .form {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
}

.form p {
  text-align: left;
}

@media (max-width: 500px) {
  .form p {
    padding-top: 5%;
    /* text-align: center; */
    margin: 0 auto;
  }
}

.label-name {
  margin-top: 20px;
  text-align: left;
}

.label-email {
  margin-top: 20px;
  text-align: left;
}
.label-subject {
  margin-top: 20px;
  text-align: left;
}

.label-message {
  margin-top: 20px;
  text-align: left;
}

.form-input {
  width: 75%;
  padding: 12px;
  margin-top: 10px;
  border: 1px solid #ccc;
  background: #ffffff;
  color: #000;
  font-family: sans-serif;
  font-size: 12px;
  line-height: normal;
  box-sizing: border-box;
  border-radius: 2px;
}

@media (max-width: 500px) {
  .form-input {
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    border: 1px solid #ccc;
    background: #ffffff;
    color: #000;
    font-family: sans-serif;
    font-size: 12px;
    line-height: normal;
    box-sizing: border-box;
    border-radius: 2px;
    margin: 0 auto;
  }
  .label-name,
  .label-subject,
  .label-email,
  .label-message {
    margin: 20px 0px 10px 0px;
  }
}

@media (max-width: 800px) {
  .form-input {
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    border: 1px solid #ccc;
    background: #ffffff;
    color: #000;
    font-family: sans-serif;
    font-size: 12px;
    line-height: normal;
    box-sizing: border-box;
    border-radius: 2px;
    margin: 0 auto;
  }
}

.form-input-message {
  margin: 1%;
  height: 25%;
}

/* Our Story */

/* .our-story-containers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
} */

@media (max-width: 500px) {
  .os-para {
    padding-left: 100px;
    width: "100%";
    height: "50%";
  }
}

.story-parallax-bg {
  opacity: 0.8;
  color: black;
  background-position: center center;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.story-parallax-bg h1 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 900;
}

.story-parallax-bg h3 {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.story-child-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px;
  border-bottom: 1px solid rgba(192, 192, 192, 0.3);
}

@media (max-width: 800px) {
  .story-child-1 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 50px;
    border-bottom: 1px solid rgba(192, 192, 192, 0.3);
    flex-wrap: wrap-reverse;
  }
}

@media (max-width: 500px) {
  .story-child-1 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid rgba(192, 192, 192, 0.3);
    flex-wrap: wrap-reverse;
  }

  .react-parallax-bgimage {
    top: 40%;
    left: 80% !important;
  }
}

.story-child-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px;
}

@media (max-width: 800px) {
  .story-child-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 50px;
  }
}

@media (max-width: 500px) {
  .story-child-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
  }
}

.o-s-c-child-1 {
  width: 25%;
}

@media (max-width: 800px) {
  .o-s-c-child-1 {
    width: 90%;
  }
}

@media (max-width: 500px) {
  .o-s-c-child-1 {
    width: 90%;
    margin: 0;
    padding: 0;
  }
}

.o-s-c-child-2 {
  width: 75%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.8);
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 500px) {
  .o-s-c-child-2 {
    width: 100%;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.8);
    /* border: 1px solid blue; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.o-s-c-child-2 h1 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 900;
}

.o-s-c-child-2 h4 {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.our-story-container {
  margin-top: 50px;
  width: 100%;
}

.our-story-container h3 {
  font-size: 25px;
  font-weight: 900;
  letter-spacing: 8px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 41px;
  background: rgba(0, 0, 0, 0.8);
  display: inline-block;
  padding: 10px 25px;
}

.our-story-container p {
  margin: 50px;
  text-align: left;
  line-height: 2em;
}

@media (max-width: 500px) {
  .our-story-container p {
    margin: 10px;
    text-align: left;
    line-height: 2em;
  }
}

.parallax-bg {
  background-position: center center;
  height: 500px;
}

.parallax-h1 {
  color: #ffffff;
  font-size: 40px;
  font-weight: 900;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* Leadership */

.lea-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.lea-story {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin: 0 auto;
}

/* Brand Builders */

.bb-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 50px;
}

.bb-container p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #ffffff;
}

.brand-h3 {
  font-size: 25px;
  margin-top: 50px;
  font-weight: 900;
  margin-bottom: 25px;
  letter-spacing: 8px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 41px;
  background: rgba(0, 0, 0, 0.8);
  display: inline-block;
  padding: 10px 25px;
}

.bb-container-children {
  width: 29%;
  margin: 2% 2% 2% 2%;
  height: 300px;
}

@media (max-width: 500px) {
  .bb-container-children {
    width: 90%;
    margin: 2% 2% 2% 2%;
    height: 300px;
  }

  .bb-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 50px;
    justify-content: center;
  }

  .bb-container p:nth-child(10) {
    display: none;
  }

  .bb-container p:nth-child(12) {
    display: none;
  }
}

.bb-container-children-last {
  width: 25%;
  padding: 2% 2% 2% 2%;
  height: 300px;
  margin: 2% auto;
}

.image {
  background-color: black;
  opacity: 0.82;
  background-position: cover;
}

/* gallery */

.gallery-h3 {
  font-size: 25px;
  margin-top: 50px;
  font-weight: 900;
  margin-bottom: 50px;
  letter-spacing: 8px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 41px;
  background: rgba(0, 0, 0, 0.8);
  display: inline-block;
  padding: 10px 25px;
  text-shadow: 0px 0px 2px #ffff;
}

/* leadership */
.tracy-container {
  display: flex;
  justify-content: center;
  padding: 50px;
  align-items: center;
  background: transparent;
  height: auto;
  width: 100%;
  border-bottom: 1px solid rgba(192, 192, 192, 0.3);
}

.tracy-info {
  text-align: left;
  width: 50%;
  color: #000000;
  line-height: 41px;
  /* margin-left: 100px; */
}

@media (max-width: 800px) {
  .tracy-info {
    text-align: left;
    width: 100%;
    color: #000000;
    line-height: 41px;
  }
}

.james-container {
  display: flex;
  justify-content: center;
  padding: 50px;
  align-items: center;
  background: transparent;
  height: auto;
  width: 100%;
}

.james-info {
  text-align: left;
  width: 50%;
  color: #000000;
  line-height: 41px;
  /* margin-right: 100px;
   */
}

@media (max-width: 800px) {
  .james-info {
    text-align: left;
    width: 100%;
    color: #000000;
    line-height: 41px;
    /* margin-right: 100px;
     */
  }
}

/* Profile Images + shadows*/
.img-squared {
  border-radius: 3px;
  border: 1px solid rgba(192, 192, 192, 0.3);
}

.shadow {
  box-shadow: 0 0px 40px 0 rgba(0, 0, 0, 0.3) !important;
}

.shadow-lg {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4) !important;
}

/* Footer */

footer {
  display: flex;
  flex-direction: row;
  color: #ffffff;
  background: #0a0a0c;
  justify-content: center;
}
@media (max-width: 800px) {
  footer {
    display: flex;
    color: #ffffff;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

@media (max-width: 500px) {
  footer {
    display: flex;
    justify-content: center;
  }
}

.footer-inner {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 50px 120px;
  align-items: baseline;
}
.footer-inner .quick-links {
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2.1px;
  line-height: 34px;
  font-size: 17px;
}
.footer-inner h2 {
  font-weight: 900;
}

.footer-inner h4 {
  font-weight: 700;
  letter-spacing: 0.17px;
  line-height: 34px;
}

@media (max-width: 800px) {
  .footer-inner h4 {
    font-weight: 700;
    letter-spacing: 0.17px;
    line-height: 34px;
    text-align: center;
  }
}

.footer-inner p {
  width: 431px;
  letter-spacing: 0.17px;
  font-size: 13px;
  line-height: 1.9rem;
}

.footer-inner h2 {
  text-transform: uppercase;
  letter-spacing: 0.11px;
  font-weight: 900;
}

@media (max-width: 800px) {
  .footer-inner h2 {
    text-transform: uppercase;
    letter-spacing: 0.11px;
    font-weight: 900;
    text-align: center;
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .footer-inner h2 {
    text-transform: uppercase;
    letter-spacing: 0.11px;
    font-weight: 900;
    margin-bottom: 15px;
    text-align: center;
    font-size: 15px;
  }
}

.footer-inner .contact-link {
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 10px 25px;
  border: 1px solid #ffffff;
  font-size: 12px;
  letter-spacing: 2.2px;
}

@media (max-width: 500px) {
  .footer-inner .contact-link {
    text-decoration: none;
    text-align: center;
    background: transparent;
    text-transform: uppercase;
    color: #ffffff;
    padding: 10px;
    border: 2px solid #ffffff;
    font-size: 15px;
    font-weight: 900;
    letter-spacing: 1.2px;
    width: auto;
    margin: 0 auto;
  }
}

.footer-inner .contact-link:hover {
  background: #ffffff;
  color: #222222;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.footer-link {
  color: #ffffff;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.17px;
}
.footer-link:hover {
  color: #a1a1a1;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow: hidden;
}

.logo-container {
  display: flex;
  flex-direction: row;
  margin: 5%;
}

@media (max-width: 500px) {
  .logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10% 11px 0px 0px;
  }

  .footer-logos {
    padding-right: 5%;
    margin: 0 auto;
    width: 27px !important;
  }
}

.footer-logos {
  padding-right: 5%;
  margin: 0 auto;
  width: 35px;
}

.work-with-us {
  margin: 0 auto;
}

.homepage-logo {
  margin: 0 auto;
  width: 90%;
}

.footer-insta-button {
  fill: #ffffff;
  /* border: 1px solid #ffffff; */
  padding: 10px;
  background: transparent;
}

.footer-insta-button:hover {
  fill: #222222;
  border: 1px solid #ffffff;
  padding: 10px;
  background: #ffffff;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.footer-fb-button {
  fill: #ffffff;
  /* border: 1px solid #ffffff; */
  padding: 10px;
  background: transparent;
}

.footer-fb-button:hover {
  fill: #222222;
  /* border: 1px solid #ffffff; */
  padding: 10px;
  background: #ffffff;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media (max-width: 800px) {
  .footer-inner {
    text-align: left;
    padding: 0px 95px;
    margin-top: 40px;
  }
}

@media (max-width: 500px) {
  .footer-inner {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 15px;
    padding: 16px;
    flex-wrap: nowrap;
    margin-top: 15px;
    width: 250px;
  }
}

.footer-location {
  display: flex;
  width: 250px;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.footer-social {
  display: flex;
  width: 140px;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.footer-social a {
  fill: #ffffff;
  cursor: pointer;
  width: 20px;
  margin: 0px 0px 0px 0px;
}

.footer-social a:hover {
  fill: #a1a1a1;
  transition: all 0.5s ease;
  cursor: pointer;
}

.footer-contact {
  display: flex;
  width: 220px;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.footer-contact a {
  color: #ffffff;
  text-decoration: none;
}

.footer-contact a:hover {
  color: #a1a1a1;
  transition: all 0.5s ease;
  cursor: pointer;
}

.footer-contact a:last-child {
  width: 80px;
}

.footer-copyright {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background: #0a0a0c;
}

.footer-copyright a {
  color: white;
}

.footer-copyright a:hover {
  color: #a1a1a1;
}

.footer-copyright p {
  color: #ffffff;
  font-size: 14px;
}

.error-404 {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 600px;
}

.error-p {
  background: transparent;
  text-transform: uppercase;
  color: #222222;
  padding: 13px 26px;
  border: 2px solid #222222;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 1.2px;
  margin-top: 200px;
  margin-bottom: 100px;
  width: auto;
}

.error-p:hover {
  background: #222222;
  color: #ffffff;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media (max-width: 500px) {
  .error-404 {
    width: 75%;
    margin: 0 auto;
  }
  .error-p{
     margin-top: 100px;
  }
}
